import React, { useEffect, useContext, useState } from 'react';
// import VerifiedOnBoarding from './verified/verifiedOnboarding';
import NonVerifiedOnboarding from './nonVerified/nonVerifiedOnBoarding';
import VerifiedOnboarding from './verified/verifiedOnboarding';
import AppLogoHandler from '../../commonComponents/appComponents/topBar/AppLogoHadler';
import SideBarHandler from '../../commonComponents/appComponents/sideBar/sideBarHadler';
import withRouter from 'src/polyfils/customRouter';
import { contextPath } from 'src/constants';
import { useQuery } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import LandingProfileCard from 'src/components/spacedonation/commonComponents/appComponents/profilecard/landingProfileCard';
import UserContext from '../../store/user-context';
import { useCookies } from 'react-cookie';
import analytics from '../../../../adobe-analytics/analytics';
import { SPACEANALYTICS } from '../../../../adobe-analytics-space/analytics-space-dataelements';
import NpoSpark from '../npoSpark/NpoSpark';
import Loader from '../../commonComponents/appComponents/loader/loader';
const config = require(`src/config.${process.env.NODE_ENV}.json`);

export interface Props {
    history: any[];
}

const isMobileResolution = () => window.innerWidth <= 425;

const service: Service = SpaceDonationService;

class Onboarding extends React.Component<Props> {
    public render() {
        return (
            <div>
                <OnboardingSection {...this.props} />
            </div>
        );
    }
}

// @ts-ignore
export default withRouter(Onboarding);

const OnboardingSection: React.SFC<Props> = (props: Props) => {
    useEffect(() => {
        analytics.trackPageInfoSpaceExternal(
            SPACEANALYTICS.onboardingSpaceExternalPage
        );
    }, []);
    const userContext = useContext(UserContext);
    const [, setCookie] = useCookies();
    const { status, data, isFetching } = useQuery(
        ['autheticate'],
        service.autheticate,
        { refetchOnWindowFocus: false }
    );
    localStorage.setItem('isLoggedIn', 'false');
    const [addNewOrganization, setAddNewOrganization] = useState(false);
    const [addNewVerifiedOrganization, setAddNewVerifiedOrganization] =
        useState(false);
    const [verifiedOrganizationData, setVerifiedOrganizationData] = useState({});
    useEffect(() => {
        if (
            !isFetching &&
            'success' === status &&
            data?.token &&
            data?.spaceOrganization
        ) {
            setCookies(setCookie, data);
            userContext.login(data.token, data.spaceOrganization);
        }
        if (!isFetching && 'error' === status) {
            userContext.logout();
            window.location.href = `//${config.spaceDonationLoginPage}`;
        }
    }, [isFetching]);

    // @ts-ignore
    const myOrgs = userContext?.organization?.myOrgs;
    return (
        <>
            {isFetching && <Loader />}
            <AppLogoHandler>
                <SideBarHandler
                    hideOnMount={true}
                    showOnUnmount={!isMobileResolution()}
                >
                    {data?.errorCode === '400' &&
                        data?.errorMessage === 'User email address not validated' ? (
                        <NpoSpark />
                    ) : (
                        <>
                            {!isFetching &&
                                addNewOrganization &&
                                !addNewVerifiedOrganization && (
                                    <NonVerifiedOnboarding
                                        data={data}
                                        history={props.history}
                                        contextPath={contextPath}
                                    />
                                )}
                            {!isFetching &&
                                addNewVerifiedOrganization &&
                                !addNewOrganization && (
                                    <VerifiedOnboarding
                                        data={verifiedOrganizationData}
                                        history={props.history}
                                        contextPath={contextPath}
                                    />
                                )}

                            {!isFetching &&
                                'success' === status &&
                                myOrgs &&
                                // myOrgs.length > 0 &&
                                !addNewOrganization &&
                                !addNewVerifiedOrganization && (
                                    <LandingProfileCard
                                        data={data}
                                        setVerifiedOrganizationData={setVerifiedOrganizationData}
                                        setAddNewOrganization={setAddNewOrganization}
                                        setAddNewVerifiedOrganization={
                                            setAddNewVerifiedOrganization
                                        }
                                        {...props}
                                    />
                                )}
                        </>
                    )}
                </SideBarHandler>
            </AppLogoHandler>
        </>
    );
};


const setCookies = (setCookie: any, data: any) => {
    setCookie('donationsAuthToken', data.token, { path: '/' });
    setCookie('userId', data.userId, { path: '/' });

    const replacer = new RegExp(',', 'g');
    const userRoleStr = data.roles.toString().replace(replacer, '/');
    setCookie('userRole', userRoleStr, {
        encode: (val: any) => val,
        path: '/',
    });
};
