import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import '../../../styles/donationOfMoney.css';
import '../../../styles/formStyles.css';
import '../../../styles/orgProfile.css';

import { Button, TextareaAutosize, withStyles } from '@material-ui/core';
import FormComponent from 'dynamic-form-components/lib/components/FormComponent';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { resetForm, saveFormData, saveFormDataFailure, updateFormSuccess } from 'dynamic-form-components/lib/state/form/formDataActions';
import {
    isAwaitingForPaymentAcknowledgment,
    isDraft,
    isRequestForInformation,
    isSavedAppendixC,
    isUnderCAReview,
    resolvePath,
} from 'src/components/common/utils';
import SuccessAlertDailog from 'src/components/dialogComponents/SuccessAlertDailog';
import LoaderComponent from 'src/components/LoaderComponent';
import {
    closeDonationApprovalRespDailog,
    donationApprovalData,
    donationApprovalFailure
} from '../../../state/donationOfApproval/donationOfApprovalActions';
import { cityStateMappingDataLoad, getOrgProfile } from '../../../state/OrgProfile/orgProfileActions';
import { downloadReportData } from '../../../state/report/reportDataActions';

import {
    contextPath,
    countryCodeToMarketCode,
    DONATION_TYPE,
    donationSuccessTxt,
    editableDonationStatuses,
    DonationStatus
} from '../../../constants';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { divisionDataLoad } from '../../../state/divisionData/divisionDataActions';
import { loggedInUserLoadSuccess } from '../../../state/loggedInUser/loggedInUserActions';
import { selectedData } from '../../../state/selectedData/selectedDataActions';
import { convertFromUtcToLocal, getDateOnlyForLocale } from '../../common/DateUtils';
import styles from '../../menu/ThemeStyle';
import { routePaths, tableTranslations } from './../../../constants';
import DeleteDonation from '../DeleteDonation';
import { countryList } from '../../../static/countryList';
import analytics from '../../../adobe-analytics/analytics';
import tracker from '../../../adobe-analytics/tracker';
import AppendixFileAttachment from './AppendixFileAttachment'
import GlobalDonationDataService from '../../../service'
import { viewOrDownloadFile } from 'src/state/ManageWorkflow/fileActions';
import { ANALYTICS } from '../../../adobe-analytics/analytics-dataelemets';
import ScrollToElementComponent from 'src/components/common/ScrollToElementComponent';
const config = require(`./../../../config.${process.env.NODE_ENV}.json`);
declare global {
    interface Window {
        donationAttachments: any,
        organizationAttachments: any,
        divisions: any,
        provinceList: any
    }
}

export interface OwnProps {
    formAlias: string;
    classes: any;
    appi18n: any;
    orgProfile: any;
    formState: any;
    isNewRequest?: any;
}

export interface StateProps {
    submissionData?: any;
    approvalSuccessDetail: any;
    selectedLang: any;
    selectedDonationData?: any;
    loggedInUserDetail: any;
    formState: any;
    approvalSuccess: any;
    approvalFailure: any;
    approvalLoading: any;
    divisionDataLoading: any;
    divisionData: any;
    cityStateMappingLoading: boolean;
    cityStateMapping: any;
    donationStatus: {
        isAppendixCSaved: boolean,
        isDraft: boolean,
        isAwaitingForPaymentAcknowledgment: boolean,
        isRequestForInfo: boolean,
        isCaReview: boolean
    }
}

export interface DispatchProps {
    submitDonation: (data: any) => void;
    downloadPdf: (data: any) => void;
    addDataToStore: (data: any) => void;
    getOrgProfile: (data: any) => void;
    approveDonationRequest: (data: any) => void;
    setLoggedInUserData: (data: any) => void;
    getDivisions: (filter: any) => void;
    getCityStateMapping: (data: any) => void;
    updateFormSuccessStatus: () => void;
    downloadFile: (data: any) => void;
    closeDonationApprovalDialog: () => void;
    updateAproveFormSuccessStatus: () => void;
    updateApproveDonationStatus: (data: any) => void;
    resetFormState: () => void;
}

const APPENDIX_C_VISIBLE_STATUS = ['AWAITING_PAYMENT_CONFIRMATION', 'SAVED_APPENDIX_C', 'COMPLETED', 'WAITING_FOR_APPENDIX_C']
const appi18n: any = {
    backBtnTxt: {
        en: 'Back',
        es: 'atrás',
        fr: 'Retour',
        ja: 'バック',
        zh: '背部'
    },
    viewMoreDetails: {
        en: 'View More Details',
        es: 'Ver más detalles',
        fr: 'Voir plus de détails',
        ja: '詳細を見る',
        zh: '查看更多詳情'
    },
    ...tableTranslations
}
const donationType: string = `type=${DONATION_TYPE.MONETARY}`;

class DonationOfMoney extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public componentDidMount() {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.monetaryDonation.requestPage);
        this.addEventListener();
    }
    public state = {
        formAlias: 'donationOfMoney',
        enableAttachmentForChina: false,
        showSuccessChina: false,
        showSubmitModel: false,
        appendixBDownloading: false,
        showChinaDialog: false,
        paymentAcknowledged: false,
        showModel: false
    };

    public options: any;
    public submitCount: number = 0;
    public componentWillMount() {
        if (this.props.loggedInUserDetail.countryCode === 'IN') {
            this.props.getDivisions({
                marketCode: countryCodeToMarketCode[this.props.loggedInUserDetail.countryCode]
            });
        }
        if (this.props.loggedInUserDetail.countryCode) {
            this.props.getCityStateMapping({
                countryCode: this.props.loggedInUserDetail.countryCode
            })
        }
        if (window.donationAttachments) {
            delete window.donationAttachments;
        }
        if (window.organizationAttachments) {
            delete window.organizationAttachments;
        }
        if (this.props.isNewRequest) {
            this.options = {
                ...this.options,
                noAlerts: true,
                language: this.props.selectedLang
            }
        }
        else if (this.props.donationStatus.isRequestForInfo || this.props.donationStatus.isDraft
            || this.props.donationStatus.isAwaitingForPaymentAcknowledgment) {
            this.options = {
                ...this.options,
                noAlerts: true,
                language: this.props.selectedLang
            }
        } else {
            this.options = {
                ...this.options,
                noAlerts: true,
                readOnly: true,
                language: this.props.selectedLang
            }
        }
        this.setFormAlias();
    }

    public componentWillUnmount() {
        if (window.donationAttachments) {
            delete window.donationAttachments;
        }
        if (window.organizationAttachments) {
            delete window.organizationAttachments;
        }
        this.removeEventListener();
    }

    private isMxUser = () => {
        return this.props.loggedInUserDetail.countryCode === 'MX'
    }

    private showChinaMonetaryDialog = (_isAppendixC: boolean) => {
        this.setState({
            showChinaDialog: true
        })
    }

    private isChinaUser = () => {
        return false;
        // return this.props.loggedInUserDetail.countryCode === 'CN'
    }

    public componentWillReceiveProps(newProps: any) {

        if (this.isChinaUser() &&
            newProps.approvalSuccessDetail?.data?.donationTaskDTOS &&
            newProps.approvalSuccessDetail?.data?.donationTaskDTOS[0]?.donation?.currentStatusCode
            === DonationStatus.WaitingforAppendixCReview) {
            this.setState({
                paymentAcknowledged: true
            })
        }
        if (newProps.selectedLang !== this.props.selectedLang) {
            this.options = {
                ...this.options,
                language: newProps.selectedLang,
            }
        }
        if (newProps.divisionData && newProps.divisionData.length) {
            window.divisions = newProps.divisionData;
        }
        if (newProps.cityStateMapping && newProps.cityStateMapping.length > 0) {
            const provinceList: any = [];
            newProps.cityStateMapping.forEach((item: any) => {
                if (item.label) {
                    provinceList.push(item.label)
                }
            })
            window.provinceList = provinceList;
        }
        if (this.props.loggedInUserDetail !== newProps.loggedInUserDetail) {
            if (newProps.loggedInUserDetail.countryCode === 'IN') {
                this.props.getDivisions({
                    marketCode: countryCodeToMarketCode[newProps.loggedInUserDetail.countryCode]
                });
            }
            if (newProps.loggedInUserDetail.countryCode) {
                this.props.getCityStateMapping({
                    countryCode: newProps.loggedInUserDetail.countryCode
                })
            }
        }

        if ((newProps.approvalSuccessDetail !== this.props.approvalSuccessDetail) && this.isMxUser()) {

            const donation = (newProps?.approvalSuccessDetail &&
                newProps?.approvalSuccessDetail?.data?.donationTaskDTOS) ?
                newProps?.approvalSuccessDetail?.data?.donationTaskDTOS[0].donation : {};


            this.redirectToMoreDetails(donation);
        }


        if ((newProps.formState.formSaveSuccess && !this.props.formState.formSaveSuccess) && this.isChinaUser()) {
            const donation = newProps.formState.formSaveResponse ?? {};
            this.downloadAppendixFiles(newProps.formState.formSaveResponse);
            this.props.addDataToStore({
                value: {
                    donation,
                    currentStatusCode: donation.currentStatusCode,
                    id: donation.id,
                },
                key: 'donationOfMoney'
            });
        }

        this.setFormAlias();
        this.translateGovernanceDetailsHeaders();
    }

    private onDonationAck = () => {
        this.onDonationReSubmit({
            data: {}
        })
    }

    private onDonationSubmit = (submission: any) => {
        this.submitCount = this.submitCount + 1;

        if (this.props.formState.formSaveLoading) {
            return true;
        }
        // const donationType = 'donationOfMoney';
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.FILL_MORE_BUTTON_FOR_MONEY.name,
            location: ANALYTICS.monetaryDonation.requestPage
        });
        if (this.props.formState.formSaveLoading) {
            return true;
        }
        const formData = new FormData();
        const orgData = {
            ...submission.data.organization,
            orgAdditionalDetails: {
                isRegisteredNew: false
            }
        }
        const donationData = {
            ...submission.data,
            donationDetail: {
                ...submission.data.donationDetail,
                divisionName: submission?.data?.donationDetail?.division?.name || '',
                divisionCode: submission?.data?.donationDetail?.division?.code || ''
            },
            organization: {
                ...submission.data.organization,
                orgAdditionalDetails: {
                    isRegisteredNew: false
                }
            }
        }
        this.props.setLoggedInUserData(orgData);
        formData.append('donation', JSON.stringify(donationData));
        const donationAttachments = window.donationAttachments;
        const organizationAttachments = window.organizationAttachments;
        if (Array.isArray(donationAttachments)) {
            donationAttachments.forEach((attachment: any) => {
                formData.append('donationAttachments', attachment);
            });
        }
        if (Array.isArray(organizationAttachments)) {
            organizationAttachments.forEach((attachment: any) => {
                formData.append('organizationAttachments', attachment);
            });
        }
        if (window.donationAttachments) {
            delete window.donationAttachments;
        }
        if (window.organizationAttachments) {
            delete window.organizationAttachments;
        }

        if (this.props.loggedInUserDetail.countryCode === 'MX' || this.isChinaUser()) {
            formData.append('saveAsDraft', 'true');
        }

        if (this.isChinaUser()) {
            this.setState({ enableAttachmentForChina: false })
        }
        this.setState({
            showModel: true
        })

        if (this.submitCount > 1) {
            return true
        }
        this.setState({ showSubmitModel: true })
        this.props.submitDonation(
            {
                formAlias: this.state.formAlias,
                formData: { data: formData },
                submissionUrl: `${config.gdmsServiceUrl}/donations/attachments`
            }
        );
        return null;
    }

    private downloadAppendixFiles = async (responseData: any) => {
        let appendixType = 'appendixb'
        if (this.props.donationStatus.isAppendixCSaved || this.props.donationStatus.isAwaitingForPaymentAcknowledgment) {
            appendixType = 'appendixc'
        }
        const pdfInfo = {
            appendixType,
            donation: responseData,
            donationType: responseData.donationType,
            langCode: this.props.selectedLang
        }

        this.setState({
            appendixBDownloading: true
        })
        await GlobalDonationDataService.downloadProjectFile(pdfInfo)
        this.setState({
            appendixBDownloading: false,
            showSuccessChina: true,
            enableAttachmentForChina: true
        }, () => {
            ScrollToElementComponent('appendixBUpload', 'start')
        })
    }

    private handleClick = () => {
        this.submitCount = 0;
        if (this.props.approvalSuccess) {
            this.props.closeDonationApprovalDialog()
            this.props.updateAproveFormSuccessStatus()
            this.props.updateApproveDonationStatus({})
        }
        if (this.isMxUser()) {

            this.props.updateFormSuccessStatus();
            this.redirectToMoreDetails(this.props.formState.formSaveResponse)
            analytics.trackEventInfo({
                type: tracker.EVENTS.CLICK,
                actionOn: tracker.ACTION_ON.CONFIRM_BUTTON_FOR_MONEY.name,
                location: ANALYTICS.monetaryDonation.dashboardPage
            });
        } else {
            this.props.history.push(`${contextPath}/dashboard/donation-money?type=donation-money`);
        }

    }

    private successDialogOk = () => {
        this.setState({ showSubmitModel: false })

        this.props.resetFormState()

        this.submitCount = 0;
        if (this.isChinaUser()) {
            this.setState({
                showSuccessChina: false
            });
        } else {
            this.handleClick();
        }

    }

    private redirectToMoreDetails = (currentDonation: any) => {
        this.loadDonationData(currentDonation)
        this.props.history.push(`${contextPath}${routePaths.mx_fill_more_details}?${donationType}`);
    }

    private loadDonationData = (currentDonation: any) => {
        this.props.addDataToStore({
            value: {
                donation: currentDonation,
                currentStatusCode: currentDonation.currentStatusCode,
                id: currentDonation.id,
            },
            key: 'donationOfMoney'
        });
    }


    private onCustomEvent = (event: any) => {

        const data = {
            donationDetail: {
                ...event.data,
                facilityNo: event.data.facility.facilityNo,
            },
            donationType: 'donationOfMoney'
        }
        this.props.downloadPdf(data);
    }

    private onDonationReSubmit = (submission: any) => {
        if (this.props.approvalLoading) {
            return true;
        }
        let waaSTasks: any = [];
        if (this.props.donationStatus.isRequestForInfo) {
            waaSTasks = [
                {
                    donation: {
                        ...this.props.selectedDonationData.donationOfMoney.donation,
                        ...submission.data
                    },
                    waaSTask: {
                        comments: submission?.data?.waaSTask?.reviewComments ? submission.data.waaSTask.reviewComments : '',
                        taskId: this.props.selectedDonationData.donationOfMoney
                            && this.props.selectedDonationData.donationOfMoney.waaSTaskMessage
                            ? this.props.selectedDonationData.donationOfMoney.waaSTaskMessage.taskId : '',
                        taskName: this.props.selectedDonationData.donationOfMoney
                            && this.props.selectedDonationData.donationOfMoney.waaSTaskMessage
                            ? this.props.selectedDonationData.donationOfMoney.waaSTaskMessage.taskName : '',
                        requestId: this.props.selectedDonationData.donationOfMoney
                            ? this.props.selectedDonationData.donationOfMoney.donation.id : '',
                        variables: {
                            userAction: 'resubmit',
                            user: this.props.loggedInUserDetail.id
                        }
                    }
                }
            ];

            if (this.isMxUser() || this.isChinaUser()) {
                waaSTasks[0].saveAsDraft = true;
            }

        } else {
            let userAction = 'acknowledgePayment';
            if (this.isChinaUser() && this.props.donationStatus.isAwaitingForPaymentAcknowledgment) {
                userAction = 'saveAndDownload'
            }
            waaSTasks = [
                {
                    donation: {
                        ...this.props.selectedDonationData.donationOfMoney.donation,
                        ...submission.data
                    },
                    waaSTask: {
                        taskId: this.props.selectedDonationData.donationOfMoney
                            && this.props.selectedDonationData.donationOfMoney.waaSTaskMessage
                            ? this.props.selectedDonationData.donationOfMoney.waaSTaskMessage.taskId : '',
                        taskName: this.props.selectedDonationData.donationOfMoney
                            && this.props.selectedDonationData.donationOfMoney.waaSTaskMessage
                            ? this.props.selectedDonationData.donationOfMoney.waaSTaskMessage.taskName : '',
                        requestId: this.props.selectedDonationData.donationOfMoney
                            ? this.props.selectedDonationData.donationOfMoney.donation.id : '',
                        variables: {
                            userAction,
                            user: this.props.loggedInUserDetail.id
                        }
                    }
                }
            ];
        }
        this.props.approveDonationRequest({ donationType: 'DONATION_OF_MONEY', waasTasks: waaSTasks });
        return null;
    }
    private translateGovernanceDetailsHeaders = () => {
        const boardMembersHeader = document.querySelectorAll('.board-members-cols')
        const dateValue = (this.props.selectedDonationData.donationOfMoney) ?
            JSON.parse(JSON.stringify(this.props.selectedDonationData.donationOfMoney)) : {};
        // @ts-ignore
        if (boardMembersHeader !== null) {
            // @ts-ignore
            const showCitySateOfResidence = this.props.isNewRequest ? true :
                (editableDonationStatuses.includes(dateValue?.donation?.currentStatusCode)
                    || dateValue?.donation?.organization?.boardMembers?.[0]?.cityOfResidence);
            // @ts-ignore
            boardMembersHeader.forEach((item: any, _index: number) => {
                if (item.id === 'cityOfResidence' || item.id === 'stateOfResidence' || item.id === 'countryOfResidence') {
                    item.style = showCitySateOfResidence ? 'display: block;' : 'display: none';
                }
                else if (item.id === 'birthPlace') {
                    item.style = showCitySateOfResidence ? 'display: none' : 'display: block;';
                }
                item.innerHTML = appi18n.boardMemberHeaders?.[item?.id]?.[this.props.selectedLang];
            })
        }

        const executiveOfficersHeader = document.querySelectorAll('.executive-officers-cols')
        // @ts-ignore
        if (executiveOfficersHeader !== null) {
            // @ts-ignore
            const showCitySateOfResidence = this.props.isNewRequest ? true :
                (editableDonationStatuses.includes(dateValue?.donation?.currentStatusCode)
                    || dateValue?.donation?.organization?.executiveOfficerHeaders?.[0]?.cityOfResidence);
            // @ts-ignore
            executiveOfficersHeader.forEach((item: any, _index: number) => {
                if (item.id === 'cityOfResidence' || item.id === 'stateOfResidence' || item.id === 'countryOfResidence') {
                    item.style = showCitySateOfResidence ? 'display: block;' : 'display: none';
                }
                else if (item.id === 'birthPlace') {
                    item.style = showCitySateOfResidence ? 'display: none' : 'display: block;';
                }
                item.innerHTML = appi18n.executiveOfficerHeaders?.[item?.id]?.[this.props.selectedLang];
            })
        }

        const keyPersonsHeader = document.querySelectorAll('.key-persons-cols')
        // @ts-ignore
        if (keyPersonsHeader !== null) {
            // @ts-ignore
            const showCitySateOfResidence = this.props.isNewRequest ? true :
                (editableDonationStatuses.includes(dateValue?.donation?.currentStatusCode)
                    || dateValue?.donation?.organization?.keyPersons?.[0]?.cityOfResidence);
            // @ts-ignore
            keyPersonsHeader.forEach((item: any, _index: number) => {
                if (item.id === 'cityOfResidence' || item.id === 'stateOfResidence' || item.id === 'countryOfResidence') {
                    item.style = showCitySateOfResidence ? 'display: block;' : 'display: none';
                }
                else if (item.id === 'birthPlace') {
                    item.style = showCitySateOfResidence ? 'display: none' : 'display: block;';
                }
                item.innerHTML = appi18n.keyPersonsHeaders?.[item.id]?.[this.props.selectedLang];
            })
        }

        const deleteBtns = document.querySelectorAll('.removeRow');
        if (deleteBtns !== null && !this.props.isNewRequest) {
            // @ts-ignore
            deleteBtns.forEach((btn: any, _index: number) => {
                if (btn) {
                    btn.style.display = 'none';
                }
            })
        } else if (deleteBtns !== null && this.props.isNewRequest) {
            deleteBtns.forEach((btn: any, _index: number) => {
                btn.innerHTML = appi18n.deleteBtnText?.[this.props.selectedLang];
            })
        }
        const editBtns = document.querySelectorAll('.editRow');
        if (editBtns !== null && !this.props.isNewRequest) {
            // @ts-ignore
            editBtns.forEach((btn: any, _index: number) => {
                if (btn) {
                    btn.style.display = 'none';
                }
            })
        } else if (editBtns !== null && this.props.isNewRequest) {
            editBtns.forEach((btn: any, _index: number) => {
                btn.innerHTML = appi18n.editBtnText?.[this.props.selectedLang];
            })
        }
        const messageElements = document.querySelectorAll('.editgrid-row-error.help-block');
        if (messageElements !== null) {
            messageElements.forEach((ele: any, _index: number) => {
                ele.style.display = 'none';
            })
        }
        const boardMembersInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersInvalidMsg !== null && boardMembersInvalidMsg.nextElementSibling !== null &&
            boardMembersInvalidMsg.nextElementSibling != null && boardMembersInvalidMsg.nextElementSibling.innerHTML) {
            boardMembersInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        const executiveOfficersInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersInvalidMsg !== null && executiveOfficersInvalidMsg.nextElementSibling !== null &&
            executiveOfficersInvalidMsg.nextElementSibling != null && executiveOfficersInvalidMsg.nextElementSibling.innerHTML) {
            executiveOfficersInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        const keyPersonsInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsInvalidMsg !== null && keyPersonsInvalidMsg.nextElementSibling !== null &&
            keyPersonsInvalidMsg.nextElementSibling != null && keyPersonsInvalidMsg.nextElementSibling.innerHTML) {
            keyPersonsInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        this.removeEventListener();
        this.addEventListener();
    }

    private addEventListener = () => {
        // border member fields
        // @ts-ignore
        const boardMembersSavebtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-saveRow\']');
        if (boardMembersSavebtn) {
            boardMembersSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const boardMembersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-cancelRow\']')
        if (boardMembersCancelBtn) {
            boardMembersCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const boardMembersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersAddMoreBtn) {
            boardMembersAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        // executive officers fields
        const executiveOfficersSavebtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-saveRow\']');
        if (executiveOfficersSavebtn) {
            executiveOfficersSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const executiveOfficersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-cancelRow\']')
        if (executiveOfficersCancelBtn) {
            executiveOfficersCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const executiveOfficersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersAddMoreBtn) {
            executiveOfficersAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // key persons fields
        const keyPersonsSavebtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-saveRow\']');
        if (keyPersonsSavebtn) {
            keyPersonsSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const keyPersonsCancelBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-cancelRow\']')
        if (keyPersonsCancelBtn) {
            keyPersonsCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const keyPersonsAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsAddMoreBtn) {
            keyPersonsAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        const governanceDetailsSection = document.querySelector('.donation-of-money-form .governance-details');
        if (governanceDetailsSection) {
            governanceDetailsSection.addEventListener('mouseenter', this.translateGovernanceDetailsHeaders);
        }
    }

    private removeEventListener = () => {

        // border member fields
        // @ts-ignore
        const boardMembersSavebtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-saveRow\']');
        if (boardMembersSavebtn) {
            boardMembersSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const boardMembersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-cancelRow\']')
        if (boardMembersCancelBtn) {
            boardMembersCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const boardMembersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersAddMoreBtn) {
            boardMembersAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        // executive officers fields
        const executiveOfficersSavebtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-saveRow\']');
        if (executiveOfficersSavebtn) {
            executiveOfficersSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const executiveOfficersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-cancelRow\']')
        if (executiveOfficersCancelBtn) {
            executiveOfficersCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const executiveOfficersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersAddMoreBtn) {
            executiveOfficersAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // key persons fields
        const keyPersonsSavebtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-saveRow\']');
        if (keyPersonsSavebtn) {
            keyPersonsSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const keyPersonsCancelBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-cancelRow\']')
        if (keyPersonsCancelBtn) {
            keyPersonsCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const keyPersonsAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsAddMoreBtn) {
            keyPersonsAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        const governanceDetailsSection = document.querySelector('.donation-of-money-form .governance-details');
        if (governanceDetailsSection) {
            governanceDetailsSection.removeEventListener('mouseenter', this.translateGovernanceDetailsHeaders);
        }
    }
    private onFormChange = (_event: any) => {
        this.translateGovernanceDetailsHeaders();
    }

    private setFormAlias = () => {
        if (this.props.loggedInUserDetail.countryCode === 'GB') {
            this.setState({
                formAlias: this.props.loggedInUserDetail?.primary ? 'donationOfMoneyExternalUK' : 'donationOfMoneyExternalUKFormForSecondaryUser'
            })
        } else {
            this.setState({
                formAlias: this.props?.loggedInUserDetail?.primary ? this.state.formAlias : 'donationOfMoneyNGOFormForSecondaryUser'
            })
        }
    }

    private readonly showViewMoreDetails = () => {
        return this.isMxUser()
            && !this.props.formState.formDataLoading
            && !this.props.isNewRequest
            && !this.props.donationStatus.isDraft
            && !this.props.donationStatus.isRequestForInfo
    }

    public render() {
        const reviewComments: any = {
            en: 'Review Comments',
            es: 'Revisar Comentarios',
            fr: 'Examiner Les Commentaires',
            ja: 'レビュー コメント',
            zh: '評論評論'
        }
        const resubmitSuccessMessage: any = {
            en: 'Resubmitted Donation Successfully',
            es: 'La Donación Se Volvió A Enviar Con Éxito',
            fr: 'Le Don A Été Soumis De Nouveau Avec Succès',
            ja: '寄付は正常に再提出されました',
            zh: '捐款已成功重新提交'
        }
        const submitSuccessMessage: any = {
            en: 'Donation request has been submitted successfully!',
            es: '¡La solicitud de donación se ha enviado correctamente!',
            fr: 'La demande de don a été soumise avec succès!',
            ja: '寄付のリクエストが正常に送信されました。',
            zh: '捐赠请求已成功提交！'
        }

        const submitSuccessMessageMX: any = {
            en: 'Donation successfully saved as a draft. Please proceed to fill in additional detail',
            es: 'La donación se guardó correctamente como borrador. Por favor proceda a completar detalles adicionales.',
            fr: 'Le don a été enregistré avec succès en tant que brouillon. Veuillez continuer à remplir des détails supplémentaires',
            ja: '寄付は下書きとして正常に保存されました。追加の詳細を記入するために進んでください',
            zh: '捐赠已成功保存为草稿。请继续填写其他详细信息'
        }
        const saveSuccessMessageChina: any = {
            en: 'The file download is successful, to proceed with the submission please upload signed and sealed Appendix B',
            es: 'La descarga del archivo es exitosa, para continuar con el envío, cargue el Apéndice B firmado y sellado.',
            fr: `Le téléchargement du fichier est réussi, pour procéder à la soumission, veuillez télécharger l'annexe B signée et scellée`,
            ja: 'ファイルのダウンロードが完了しました。送信を続行するには、署名および捺印された付録 B をアップロードしてください',
            zh: '文件下载成功，继续提交请上传签名并盖章的附录B'
        }

        const saveSuccessMessageChinaAppendixC: any = {
            en: 'Appendix C Successfully uploaded, please acknowledge payment',
            es: 'Apéndice C Cargado con éxito, confirme el pago',
            fr: `Annexe C Téléversé avec succès, veuillez accuser réception du paiement`,
            ja: '付録 C 正常にアップロードされました。支払いを承認してください',
            zh: '附录C 上传成功，请确认付款'
        }
        const resubmitFailureMessage: any = {
            en: 'Resubmission Of Donation Failed',
            es: 'Reenvío De Donación Fallido',
            fr: 'Échec De Resoumission Du Don',
            ja: '寄付の再提出の失敗',
            zh: '未能重新提交捐贈'
        }
        const submitFailureMessage: any = {
            en: 'Submission Of Donation Failed',
            es: 'Envío De Donación Fallido',
            fr: 'La Soumission Du Don A Échoué',
            ja: '寄付を送信できませんでした',
            zh: '捐款提交失敗'
        }
        const confirmPaymentErrorMessage: any = {
            en: 'Failed To Acknowledge Payment',
            es: 'No se pudo reconocer el pago',
            fr: `Échec de l'accusé de réception du paiement`,
            ja: '支払いの確認に失敗しました',
            zh: '无法确认付款'
        }
        const confirmPaymentSuccessMessage: any = {
            en: 'Successfully Acknowledged Payment',
            es: 'Pago reconocido correctamente',
            fr: 'Paiement reconnu avec succès',
            ja: '支払いの確認に成功',
            zh: '成功确认付款'
        }

        const savePaymentSuccessMessage: any = {
            en: 'Please upload Appendix C Document',
            es: 'Cargue el documento del Apéndice C',
            fr: `euillez télécharger le document de l'annexe C`,
            ja: '付録 C ドキュメントをアップロードしてください',
            zh: '请上传附录C文件'
        }

        const { formState, classes, loggedInUserDetail } = this.props;
        const donationOrganization: any = (this.props.selectedDonationData.donationOfMoney
            && this.props.selectedDonationData.donationOfMoney.donation
            && this.props.selectedDonationData.donationOfMoney.donation.organization
            && this.props.donationStatus.isRequestForInfo
        ) ? this.props.selectedDonationData.donationOfMoney.donation.organization : null;
        const newOrganizationData = loggedInUserDetail ? JSON.parse(JSON.stringify(loggedInUserDetail)) : {};
        const orgProfileData = donationOrganization ? donationOrganization : newOrganizationData;
        const isMXMarket = countryCodeToMarketCode[window.countryCode ||
            this.props.loggedInUserDetail.countryCode] === 'MX';
        const redirectToDashboardChina = () => {
            if (this.props.donationStatus.isAppendixCSaved) {
                this.setState({
                    showChinaDialog: false
                })
            } else {
                this.props.history.push(`${contextPath}/dashboard/donation-money?type=donation-money`)
            }

        }
        const showPaymentAck = this.isChinaUser() &&
            this.props.selectedDonationData.donationOfMoney?.donation?.donationDetail?.signedAppendixCuploadFiles
            && this.props.selectedDonationData.donationOfMoney.donation.donationDetail.signedAppendixCuploadFiles[0]
        return (<>
            <Button className={`back-btn ${this.props.classes.buttonStyles}`}
                variant='contained' color='primary'
                onClick={this.handleClick}>
                {appi18n.backBtnTxt[this.props.selectedLang]}
            </Button>
            {(!this.props.isNewRequest) && this.props.donationStatus.isRequestForInfo ?
                (<div className='review-section'>
                    <span className='review-section-text'>{reviewComments[this.props.selectedLang]}</span>
                    <br />
                    <TextareaAutosize rowsMax={8} style={{ margin: '3%', width: '94%' }}
                        placeholder={
                            (this.props.selectedDonationData.donationOfMoney.donation
                                && this.props.selectedDonationData.donationOfMoney.donation.donationDetail)
                                ? this.props.selectedDonationData.donationOfMoney.donation.donationDetail.reviewComments : ''
                        } disabled={true} />
                </div>) : (<div />)}
            <div className='donation-of-money-form'>
                <FormComponent
                    key={this.state.formAlias}
                    formAlias={this.state.formAlias}
                    options={this.options}
                    submission={{
                        data: this.props.isNewRequest && !this.props.donationStatus.isDraft ?
                            orgProfileData.orgAdditionalDetails &&
                                orgProfileData.orgAdditionalDetails.isRegisteredNew ? {
                                organization: {
                                    ...orgProfileData,
                                    authorized: '',
                                    corruptionOrBribery: '',
                                    issueReceipts: '',
                                    govtEntityRelation: '',
                                    govtOfficialRelation: '',
                                    politicalActivity: '',
                                    pastDonation: '',
                                    prevDeniedDonation: '',
                                    govtOrPoliticalOfficeRelative: '',
                                    govtOrPoliticalOffice: '',
                                    establishedDate: (orgProfileData && orgProfileData.establishedDate) ?
                                        convertFromUtcToLocal(orgProfileData.establishedDate) : '',
                                    consentSignee: {},
                                    certificateSignee: {},
                                    address: {
                                        ...orgProfileData.address
                                    }
                                }, countryList, isNewRequest: this.props.isNewRequest
                            } : {
                                organization: {
                                    ...orgProfileData,
                                    establishedDate: (orgProfileData && orgProfileData.establishedDate) ?
                                        convertFromUtcToLocal(orgProfileData.establishedDate) : '',
                                    consentSignee: {},
                                    certificateSignee: {},
                                    address: {
                                        ...orgProfileData.address
                                    }
                                }, countryList, isNewRequest: this.props.isNewRequest
                            }
                            : this.props.donationStatus.isRequestForInfo ?
                                {
                                    ...(this.props.selectedDonationData
                                        && this.props.selectedDonationData.donationOfMoney
                                        && this.props.selectedDonationData.donationOfMoney.donation)
                                        ? (this.props.selectedDonationData.donationOfMoney.donation) : {},
                                    organization: {
                                        ...orgProfileData,
                                        consentSignee: {},
                                        certificateSignee: {},
                                        establishedDate: (orgProfileData && orgProfileData.establishedDate) ?
                                            convertFromUtcToLocal(orgProfileData.establishedDate) : '',
                                        consentSignedDate: (this.props.selectedDonationData && this.props.selectedDonationData.donationOfMoney.donation?.organization) ?
                                            getDateOnlyForLocale(this.props.selectedDonationData.donationOfMoney.donation?.organization?.consentSignedDate) : '',
                                        certificateSignedDate: (this.props.selectedDonationData && this.props.selectedDonationData.donationOfMoney.donation?.organization) ?
                                            getDateOnlyForLocale(this.props.selectedDonationData.donationOfMoney.donation?.organization?.certificateSignedDate) : '',
                                    }, countryList,
                                    donationDetail: this.props.selectedDonationData.donationOfMoney ?
                                        this.props.selectedDonationData.donationOfMoney.donation.donationDetail : '',
                                    currentStatusCode: this.props.selectedDonationData.donationOfMoney ?
                                        this.props.selectedDonationData.donationOfMoney.donation.currentStatusCode :
                                        '',
                                    fillMoreDetailsBtnEnabled: countryCodeToMarketCode[window.countryCode ||
                                        this.props.loggedInUserDetail.countryCode],
                                    isNewRequest: this.props.isNewRequest
                                }
                                : {
                                    ...(this.props.selectedDonationData
                                        && this.props.selectedDonationData.donationOfMoney
                                        && this.props.selectedDonationData.donationOfMoney.donation)
                                        ? (this.props.selectedDonationData.donationOfMoney.donation) : {},
                                    organization: {
                                        ...orgProfileData,
                                        establishedDate: (orgProfileData && orgProfileData.establishedDate) ?
                                            convertFromUtcToLocal(orgProfileData.establishedDate) : '',
                                        consentSignedDate: (this.props.selectedDonationData && this.props.selectedDonationData.donationOfMoney.donation?.organization) ?
                                            getDateOnlyForLocale(this.props.selectedDonationData.donationOfMoney.donation?.organization?.consentSignedDate) : '',
                                        certificateSignedDate: (this.props.selectedDonationData && this.props.selectedDonationData.donationOfMoney.donation?.organization) ?
                                            getDateOnlyForLocale(this.props.selectedDonationData.donationOfMoney.donation?.organization?.certificateSignedDate) : '',
                                    }, countryList,
                                    donationDetail:
                                        this.props.selectedDonationData.donationOfMoney ?
                                            this.props.selectedDonationData.donationOfMoney.donation.donationDetail :
                                            '',
                                    consentSignee: {
                                        terms: true,
                                        privacyPolicy: true
                                    },
                                    certifyCheckBox1: true,
                                    certifyCheckBox2: true,
                                    certifyCheckBox3: true,
                                    currentStatusCode: this.props.selectedDonationData.donationOfMoney ?
                                        this.props.selectedDonationData.donationOfMoney.donation.currentStatusCode :
                                        '',
                                    isNewRequest: this.props.isNewRequest
                                }
                    }}
                    onSubmit={
                        !this.props.isNewRequest
                            && (this.props.donationStatus.isRequestForInfo || this.props.donationStatus.isAwaitingForPaymentAcknowledgment)
                            ? this.onDonationReSubmit : this.onDonationSubmit}
                    submissionUrl={''}
                    handleCustomEvent={this.onCustomEvent}
                    handleOnFormChange={this.onFormChange}
                />
                {
                    this.showViewMoreDetails()
                    && (<button className='btn btn-primary btn-md'
                        onClick={() => this.redirectToMoreDetails(this.props.selectedDonationData.donationOfMoney.donation)}>
                        {appi18n.viewMoreDetails[this.props.selectedLang]}
                    </button>)
                }
                {
                    (this.props.donationStatus.isDraft
                        && !this.props.formState.formDataLoading
                        && this.props.selectedDonationData.donationOfMoney.donation.countryCode === 'MX'
                        && !this.props.selectedDonationData.donationOfMoney.donation.isInternal)
                    && <DeleteDonation donationId={this.props.selectedDonationData.donationOfMoney.donation?.id}
                        dashboardPath={`${contextPath}/dashboard/donation-money?type=donation-money`} />
                }

                {(!this.props.isNewRequest || this.props.donationStatus.isDraft) &&
                    this.isChinaUser() &&
                    <AppendixFileAttachment
                        resetFileUpload={this.state.enableAttachmentForChina}
                        fileDownloader={this.props.downloadFile}
                        isReview={!(this.props.donationStatus.isDraft || this.props.donationStatus.isRequestForInfo)}
                        fileDetails={this.props.selectedDonationData.donationOfMoney?.donation?.donationDetail?.signedAppendixBuploadFiles
                            && this.props.selectedDonationData.donationOfMoney.donation.donationDetail.signedAppendixBuploadFiles[0]}
                        donationId={this.props.selectedDonationData.donationOfMoney?.donation?.id}
                        showChinaMonetaryDialog={this.showChinaMonetaryDialog}
                        uploadLabel='monetaryDonation.signedAppendixB'
                        label='monetaryDonation.uploadSignedAppendixB'
                        loadDonationData={this.loadDonationData}
                        onDonationAck={this.onDonationAck}
                    />
                }
                {(APPENDIX_C_VISIBLE_STATUS.includes(this.props.selectedDonationData.donationOfMoney?.currentStatusCode)) &&
                    this.isChinaUser() &&
                    <AppendixFileAttachment
                        resetFileUpload={this.state.enableAttachmentForChina}
                        fileDownloader={this.props.downloadFile}
                        isReview={!this.props.donationStatus.isAppendixCSaved}
                        fileDetails={this.props.selectedDonationData.donationOfMoney?.donation?.donationDetail?.signedAppendixCuploadFiles
                            && this.props.selectedDonationData.donationOfMoney.donation.donationDetail.signedAppendixCuploadFiles[0]}
                        donationId={this.props.selectedDonationData.donationOfMoney?.donation?.id}
                        showChinaMonetaryDialog={this.showChinaMonetaryDialog}
                        uploadLabel='monetaryDonation.signedAppendixC'
                        label='monetaryDonation.uploadSignedAppendixC'
                        isAppendixC={true}
                        loadDonationData={this.loadDonationData}
                        showPaymentAck={showPaymentAck}
                        onDonationAck={this.onDonationAck}
                    />
                }
            </div>
            <SuccessAlertDailog
                isAsdaTheme={loggedInUserDetail && loggedInUserDetail.countryCode === 'GB'}
                showDialog={this.isChinaUser() ? this.state.showSuccessChina : (this.props.formState.formSaveSuccess && this.state.showModel)}
                ok={this.successDialogOk}
                title={donationSuccessTxt.title[this.props.selectedLang]}
                message={isMXMarket ? submitSuccessMessageMX[this.props.selectedLang] :
                    this.isChinaUser() ? saveSuccessMessageChina[this.props.selectedLang] : submitSuccessMessage[this.props.selectedLang]}
            />
            <SuccessAlertDailog
                isAsdaTheme={loggedInUserDetail && loggedInUserDetail.countryCode === 'GB'}
                showDialog={this.props.approvalSuccess
                    && this.props.donationStatus.isRequestForInfo}
                ok={this.successDialogOk}
                title={donationSuccessTxt.title[this.props.selectedLang]}
                message={this.isChinaUser() ? saveSuccessMessageChina[this.props.selectedLang] :
                    resubmitSuccessMessage[this.props.selectedLang]}
            />
            <SuccessAlertDailog
                isAsdaTheme={loggedInUserDetail && loggedInUserDetail.countryCode === 'GB'}
                showDialog={this.state.showChinaDialog}
                ok={redirectToDashboardChina}
                title={donationSuccessTxt.title[this.props.selectedLang]}
                message={!this.props.donationStatus.isAppendixCSaved ? submitSuccessMessage[this.props.selectedLang] :
                    saveSuccessMessageChinaAppendixC[this.props.selectedLang]}
            />
            <SuccessAlertDailog
                isAsdaTheme={loggedInUserDetail && loggedInUserDetail.countryCode === 'GB'}
                showDialog={(this.props.approvalSuccess
                    && this.props.donationStatus.isAwaitingForPaymentAcknowledgment) || this.state.paymentAcknowledged}
                ok={this.handleClick}
                title={donationSuccessTxt.title[this.props.selectedLang]}
                message={(this.isChinaUser() &&
                    !this.state.paymentAcknowledged) ? savePaymentSuccessMessage[this.props.selectedLang] :
                    confirmPaymentSuccessMessage[this.props.selectedLang]}
            />
            {this.props.formState.formSaveLoading ? <LoaderComponent fullScreen={true} /> : <></>}
            <ErrorSnackbarComponent isError={formState.formSaveFailure} classes={classes}
                message={submitFailureMessage[this.props.selectedLang]} />
            {this.props.approvalLoading ? <LoaderComponent fullScreen={true} /> : <></>}
            <ErrorSnackbarComponent isError={this.props.approvalFailure
                && this.props.donationStatus.isRequestForInfo} classes={classes}
                message={resubmitFailureMessage[this.props.selectedLang]} />
            <ErrorSnackbarComponent isError={this.props.approvalFailure
                && this.props.donationStatus.isAwaitingForPaymentAcknowledgment} classes={classes}
                message={confirmPaymentErrorMessage[this.props.selectedLang]} />
            {
                (this.props.divisionDataLoading || this.props.cityStateMappingLoading
                    || this.state.appendixBDownloading)
                    ? <LoaderComponent fullScreen={true} /> : <></>
            }
        </>);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => {
    const statusCode = resolvePath('selectedDataState.addDataToStore.donationOfMoney.currentStatusCode', state, '');
    return {
        submissionData: state.submissionDataState.submissionData,
        selectedLang: state.selectedLang,
        selectedDonationData: state.selectedDataState.addDataToStore,
        loggedInUserDetail: state.loggedInUserState.userDetail,
        formState: state.formState,
        approvalSuccess: state.donationApprovalState.approvalSuccess,
        approvalFailure: state.donationApprovalState.approvalFailure,
        approvalLoading: state.donationApprovalState.approvalLoading,
        approvalSuccessDetail: state.donationApprovalState.approvalSuccessDetail,
        divisionDataLoading: state.divisionDataState.divisionDataLoading,
        divisionData: state.divisionDataState.divisionData,
        cityStateMappingLoading: state.orgProfile.cityStateMappingLoading,
        cityStateMapping: state.orgProfile.cityStateMapping,
        donationStatus: {
            isRequestForInfo: isRequestForInformation(statusCode),
            isDraft: isDraft(statusCode),
            isAwaitingForPaymentAcknowledgment: isAwaitingForPaymentAcknowledgment(statusCode),
            isCaReview: isUnderCAReview(statusCode),
            isAppendixCSaved: isSavedAppendixC(statusCode)
        }

    }
};

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    submitDonation: (data: any) => dispatch(saveFormData(data)),
    addDataToStore: (data: any) => dispatch(selectedData(data)),
    downloadPdf: (data: any) => dispatch(downloadReportData(data)),
    getOrgProfile: (data: any) => dispatch(getOrgProfile(data)),
    approveDonationRequest: (data: any) => dispatch(donationApprovalData(data)),
    setLoggedInUserData: (data: any) => dispatch(loggedInUserLoadSuccess(data)),
    getDivisions: (filter: any) => dispatch(divisionDataLoad(filter)),
    getCityStateMapping: (data: any) => dispatch(cityStateMappingDataLoad(data)),
    updateFormSuccessStatus: () => dispatch(updateFormSuccess()),
    updateAproveFormSuccessStatus: () => dispatch(saveFormDataFailure('')),
    updateApproveDonationStatus: (data) => dispatch(donationApprovalFailure(data)),
    downloadFile: (data) => dispatch(viewOrDownloadFile(data)),
    closeDonationApprovalDialog: () => dispatch(closeDonationApprovalRespDailog()),
    resetFormState: () => dispatch(resetForm())
});


export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(DonationOfMoney)));
