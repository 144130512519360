import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { i18n as i18nI } from 'i18next';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import FormComponent from 'dynamic-form-components/lib/components/FormComponent';
import { GlobalDonationState } from '../../models/globalDonationState';
import { withStyles, Typography, Link } from '@material-ui/core';
import { registerUser } from '../../state/userLogin/registerUserActions';
import styles from '../menu/ThemeStyle';
import '../../styles/formStyles.css'
import LoaderComponent from '../LoaderComponent';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import { viewOrDownloadFile } from '../../state/ManageWorkflow/fileActions';
import { contextPath } from './../../constants';
import { selectedLang } from './../../state/globalDonationActions';
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';
import landingPageLogo from '../../resources/landing_logo.svg'
const landingPage = require(`./Forms/landingPage`);
const config = require(`./../../config.${process.env.NODE_ENV}.json`);


export interface OwnProps {
    formAlias: string;
    submissionUrl?: string;
    classes: any;
    appi18n: any;
    handleSubmit: (data: any) => void;
    t: (key: string, options?: any) => ReactNode;
    i18n: i18nI
}

export interface StateProps {
    submissionData?: any;
    selectedLang: any;
    registerUserData: any;
    orgType?: any;
    formAlias?: any;
}

export interface DispatchProps {
    register: (data: any) => void;
    downloadFile: (data: any) => void;
    changeLanguage: (data: any) => void;
}

declare global {
    interface Window {
        lang: any;
        country: any;
        countryCode: any;
        countryName: any;
        __GDMS_PORTAL__: any
    }
}

class Landing extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    // let navigate = useNavigate();
    // this.props.navigation.navigate
    public state = {
        formAlias: 'landingPage'
    };

    public options: any;

    public componentWillMount() {
        document.cookie = 'forms.hflp_Authenticate_LoginService.session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.options = {
            ...this.options,
            noAlerts: true,
            language: this.props.selectedLang
        }
    }

    public componentDidMount() {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.landingPage.welcomePage);
    }

    public componentWillReceiveProps(newProps: any) {
        if (newProps.selectedLang !== this.props.selectedLang) {
            this.options = {
                ...this.options,
                language: newProps.selectedLang
            }
        }
    }

    private handleSubmit = (formData: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.LANDING_GO_BUTTON.name,
            location: ANALYTICS.landingPage.welcomePage
        });
        // As we redirect to login page. store value will be lost
        localStorage.setItem('country', formData.data.marketLocationLanguage.code);
        localStorage.setItem('countryCode', formData.data.marketLocationLanguage.code.split('_')[1]);
        localStorage.setItem('countryName', formData.data.marketLocationLanguage.value);
        localStorage.setItem('lang', formData.data.marketLocationLanguage.code.split('_')[0]);
        window.lang = formData.data.marketLocationLanguage.code.split('_')[0];
        window.countryCode = formData.data.marketLocationLanguage.code.split('_')[1];
        window.countryName = formData.data.marketLocationLanguage.value;
        this.props.changeLanguage(formData?.data.marketLocationLanguage?.code?.split('_')[0] || 'en')
        //  this.props.i18n.changeLanguage(formData?.data.marketLocationLanguage?.code?.split('_')[0] || 'en')
        localStorage.getItem('countryCode') === 'MX' ?
            this.props.i18n.changeLanguage(localStorage.getItem('country') || 'es_MX') :
            this.props.i18n.changeLanguage(localStorage.getItem('lang') || 'en')
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.LANDING_GO_BUTTON.name,
            location: ANALYTICS.landingPage.welcomePage
        });

        this.props.history.push(`${contextPath}/Public/login`)
    }

    public handleDownload = () => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.LANDING_GO_BUTTON.name,
            location: ANALYTICS.landingPage.welcomePage
        });
        const fileInfo = {
            templateKey: 'PRIVACY_POLICY',
            downloadDir: 'WW'
        }
        const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
        const dataUri = `public/files/privacy-policy?fileInfo=${encryptedFileInfo}`;
        this.props.downloadFile({ name: 'privacy-policy.docx', type: 'downloadFiles', actionUrl: `${config.gdmsServiceUrl}/${dataUri}` });
    }

    public render() {
        // @ts-ignore
        const { classes } = this.props;
        return (<>
            <div className='landing-top-section'>
                <div className='landing-logo'>
                    <img alt='GDMS Landing Page' src={landingPageLogo} />
                </div>
                <Typography className='langing-header'>Working together to make
                    <span className='header-lives'>lives</span>better</Typography>
                <Typography className='landing-sub-header'>
                    GDMS connects with nonprofits and companies in many countries around the world to help them access the funding,
                    tools and support they need to become more effective.
                </Typography>
            </div>
            <div className={`landing-box ${classes.formCenter}`}>
                <div className={`select-container ${classes.formContainer}`}>
                    <div>
                        <FormComponent
                            key={this.state.formAlias}
                            formAlias={this.state.formAlias}
                            formJsonDefinition={landingPage}
                            options={this.options}
                            submission={this.props.register}
                            onSubmit={this.handleSubmit}
                            submissionUrl={this.props.submissionUrl}
                        />
                    </div>
                </div>
                {false && <LoaderComponent fullScreen={true} />}
            </div>
            <div className='landing-footer'>
                <Link title='Privacy Policy'
                    style={{ cursor: 'pointer' }}
                    onClick={this.handleDownload}>
                    Privacy Policy
                </Link>
                <p>© Walmart Stores, Inc.</p>
            </div>
            <div className='landing-background' />
        </>);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    submissionData: state.submissionDataState.submissionData,
    selectedLang: state.selectedLang,
    registerUserData: state.registerUser,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    register: (data: any) => dispatch(registerUser(data)),
    downloadFile: (data) => dispatch(viewOrDownloadFile(data)),
    changeLanguage: (data: any) => dispatch(selectedLang(data))
});

export default withTranslation()(withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing))));
